<template>
  <!-- Hero content: will be in the middle -->
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-4">
          <div class="columns is-multiline login">
            <div class="column is-12 box-auth">
              <div class="login-header">
                <h3 class="is-size-4 login-title">RESET PASSWORD</h3>
                <h3 class="is-size-7 login-subtitle">
                  SEND PASSWORD RESET E-MAIL
                </h3>
              </div>
              <div class="login-body">
                <form @submit.prevent="processForgotPassword(username)">
                  <div class="form-group">
                    <b-field>
                      <template slot="label">
                        <span class="font-auth">YOUR E-MAIL</span>
                      </template>
                      <b-input
                        icon="envelope"
                        type="is-info"
                        pack="fal"
                        v-model="username"
                      ></b-input>
                    </b-field>
                  </div>
                  <b-button
                    tag="input"
                    native-type="submit"
                    type="is-primary"
                    value="Send Password Reset Link"
                    expanded
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { VueReCaptcha } from "vue-recaptcha-v3";
import Vue from "vue";

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

export default {
  name: "Login",
  data() {
    return {
      username: null,
      password: null
    };
  },
  methods: {
    async processForgotPassword(username) {
      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");
      this.$store
        .dispatch("forgot", { username, token })
        .then(() => {
          this.success("Success");
        })
        .catch(() => {
          this.danger("Username or Password is incorrect");
        });
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  border: 1px solid #d6dee3;
}
.login-header {
  border-bottom: 1px solid #f2f5f9;
  padding: 30px;
}
.login-title {
  color: #333d5d;
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}
.login-subtitle {
  color: #b9bbc6;
}
.login-body {
  padding: 30px;
}
.form-group {
  margin-bottom: 1.7rem;
}
</style>
